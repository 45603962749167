import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import ScrollToTop from './components/layout/ScrollToTop/ScrollToTop';
import Home from './pages/Home/Home';
// Define lazy-loaded components
const OurWork = React.lazy(() => import('./pages/OurWork/OurWork'));
const ContactUs = React.lazy(() => import('./pages/ContactUs/ContactUs'));
const AboutUs = React.lazy(() => import('./pages/AboutUs/AboutUs'));
const VacancyResult = React.lazy(() =>
  import('./pages/VacancyResult/VacancyResult')
);
const Services = React.lazy(() => import('./pages/Services/Services'));
const Career = React.lazy(() => import('./pages/Career/Career'));
const Blog = React.lazy(() => import('./pages/Blog/Blog'));
const PrivacyPolicy = React.lazy(() =>
  import('./components/UI/PrivacyPolicy/PrivacyPolicy')
);
const TermsAndCondition = React.lazy(() =>
  import('./components/UI/TermsAndCondition/TermsAndCondition')
);
const ApplyJob = React.lazy(() => import('./pages/ApplyJob/ApplyJob'));
const OurTechnologies = React.lazy(() =>
  import('./pages/OurTechnologies/OurTechnologies')
);
const BlogPage = React.lazy(() => import('./pages/Blog/BlogPage/BlogPage'));
const NotFound = React.lazy(() => import('./components/UI/404Page/404Page'));

const Routess = () => (
  <>
    <ScrollToTop />
    <Suspense
      fallback={
        <div className='loader-wrapper'>
          <img src='./loading_logo.webp' alt='AITC' />
        </div>
      }
    >
      <Routes>
        <Route path='/' element={<Home />} exact />
        <Route path='/our-work' element={<OurWork />} />
        <Route path='/contact' element={<ContactUs />}>
          <Route path={`/contact/:query`} element={<ContactUs />} />
        </Route>
        <Route path='/about' element={<AboutUs />} />
        <Route path='/result' element={<VacancyResult />} />
        <Route path='/services' element={<Services />} />
        <Route path='/career' element={<Career />} />
        <Route path='/blog' element={<Blog />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/terms-conditions' element={<TermsAndCondition />} />
        <Route path='/career/:name' element={<ApplyJob />} />
        <Route path='/technology' element={<OurTechnologies />}>
          <Route path='/technology/:title' element={<OurTechnologies />} />
        </Route>
        <Route path={`/article/:id`} element={<BlogPage />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </Suspense>
  </>
);

export default Routess;
