import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import Logo from '../../../assets/logo.png';
import MobileNavbar from './MobileNavbar';
import { Dropdown, Affix } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import axios from 'axios';
import ServiceDropDown from './services-dropdown';

const Navbar = () => {
  const [top] = useState(0);
  const [data, setData] = useState(null);
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_KEY}/stack`).then(res => {
      setData(res.data);
    });
  }, []);

  return (
    <div className='top_navbar'>
      <Affix offsetTop={top}>
        <div className='top_navbar_wrapper'>
          <div id='NAVBAR'>
            <header className='container'>
              <div className='navbar-items'>
                <div className='logo'>
                  <Link to='/'>
                    <img alt={'logo'} src={Logo} />
                  </Link>
                </div>

                <div className='desktop-nav-links_item'>
                  <ul>
                    <li>
                      <Link to={'/'}> Home </Link>
                    </li>
                    <li>
                      <Link to={'/about'}> About Us</Link>
                    </li>
                    <li id='dropdown-my'>
                      <Dropdown
                        placement='topCenter'
                        overlay={<ServiceDropDown data={data} />}
                        mouseEnterDelay={0.15}
                        mouseLeaveDelay={0.1}
                        getPopupContainer={triggerNode =>
                          triggerNode.parentElement
                        }
                      >
                        <Link
                          className='flex items-center gap-2'
                          to={'/services'}
                        >
                          Services <DownOutlined size={27} />
                        </Link>
                      </Dropdown>
                    </li>
                    <li>
                      <Link to={'/career'}>Career</Link>
                    </li>
                    <li>
                      <Link to={'/blog'}>Blog</Link>
                    </li>
                    <li>
                      <div className='navbar_hero_container_button'>
                        <Link to='/contact'>
                          <div className='hero-contact-btn'>Let's Connect</div>
                        </Link>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </header>
          </div>
          <MobileNavbar />
        </div>
      </Affix>
    </div>
  );
};

export default Navbar;
