import React, { useState, useEffect } from 'react';
import './Footer.css';
import Logo from '../../../assets/logo.png';
import { Link } from 'react-router-dom';
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { LetsConnect } from '../../UI';
import axios from 'axios';
import { AiOutlineMail } from 'react-icons/ai';
import { BsTelephone } from 'react-icons/bs';

const Footer = () => {
  const [data, setData] = useState([]);
  const [stackData, setStackData] = useState([]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_KEY}/web-settings`).then(res => {
      setData(res.data[0]);
    });
  }, []);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_KEY}/stack`).then(res => {
      setStackData(res.data);
    });
  }, []);

  return (
    <>
      <div id='Footer'>
        <LetsConnect />

        <div className='footer_wrapper container  '>
          <div className='footer_logo mb-4 py-8 flex justify-center'>
            <img src={Logo} alt='' />
          </div>
          <div className='ant-row flex justify-between '>
            <div className='ant-col ant-col-xs-12 ant-col-sm-12  ant-col-md-6 ant-col-lg-6 ant-col-xl-6'>
              <div className='footer_heading'>Services</div>

              {stackData &&
                stackData.slice(0, 6).map(e => (
                  <div className='footer_sub_heading' key={e._id}>
                    <Link
                      to={
                        `/technology/` +
                        e.title
                          .replace(/\s+/g, '-')
                          .toLowerCase()
                          .replace(/\//g, '-')
                      }
                    >
                      {' '}
                      {e.title}
                    </Link>
                  </div>
                ))}
            </div>
            <div className='ant-col ant-col-xs-12 ant-col-sm-12  ant-col-md-6 ant-col-lg-6 ant-col-xl-6'>
              <div className='footer_heading'>Company</div>
              <div className='footer_sub_heading'>
                {' '}
                <Link to='/about'> Who We Are </Link>
              </div>
              <div className='footer_sub_heading'>
                {' '}
                <Link to='/services'> What We Do </Link>
              </div>
              <div className='footer_sub_heading'>
                {' '}
                <Link to='/career'> Career </Link>
              </div>
              <div className='footer_sub_heading'>
                {' '}
                <Link to='/contact'> Contact </Link>
              </div>
              <div className='footer_sub_heading'>
                {' '}
                <Link to='/result'> Application Result </Link>
              </div>
            </div>
            <div className='ant-col ant-col-xs-12 ant-col-sm-12  ant-col-md-6 ant-col-lg-6 ant-col-xl-6'>
              {' '}
              <div className='footer_heading'>Contact Us</div>
              <div className='w-full  flex items-center  text-white text-lg gap-2'>
                <AiOutlineMail />
                <a
                  href='mailto:info@aitc.ai'
                  className=' text-white hover:text-[#ff7500]'
                >
                  info@aitc.ai
                </a>
              </div>
              <div className='w-full text-lg mt-2 text-white flex items-center gap-2'>
                <BsTelephone />
                <a
                  href='tel:01-5900970'
                  className=' text-white hover:text-[#ff7500]'
                >
                  01-5900970
                </a>
              </div>
              <div className='w-full text-lg mt-2 text-white flex items-center gap-2'>
                <BsTelephone />
                <a
                  href='tel:+1 (919) 423-3497'
                  className=' text-white hover:text-[#ff7500]'
                >
                  +1 (919) 423-3497
                </a>
              </div>
            </div>
            <div className='ant-col ant-col-xs-12  ant-col-sm-12  ant-col-md-6 ant-col-lg-6 ant-col-xl-6'>
              <div className='footer_heading'>Location</div>
              <div className='footer_sub_heading1'>
                <h2 className='text-xl text-gray-400  mb-0'>Nepal</h2>
                <a
                  href='https://goo.gl/maps/hmzC5r9AX562ELYV8'
                  className='footer_sub_heading hover:underline '
                  target='_blank'
                  rel='noreferrer'
                >
                  {' '}
                  Radhe Radhe,{' '}
                  <span className='underline'>
                    Bhaktapur - Nepal,44800
                  </span>{' '}
                </a>
                <br />
              </div>

              <div className='footer_sub_heading'>
                <h2 className='text-xl mb-0 text-gray-400'>USA </h2>

                <a
                  target='_blank'
                  rel='noreferrer'
                  className='footer_sub_heading hover:underline'
                  href='https://goo.gl/maps/GGcTjwRNXZ6wugce9'
                >
                  {' '}
                  2201 Spinks Rd, Flower Mound,
                  <span className='underline'>Texas, 75022</span>{' '}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>{' '}
      <div className='w-full text-[#aaaaaa] relative z-20 bg-[#22292b] flex flex-wrap justify-center gap-4 sm:gap-10 items-center'>
        &copy; Copyright2020 : AITC INTERNATIONAL
        <div className='flex items-center gap-4'>
          <a
            href={data.facebook_link}
            className='  text-[#aaaaaa] hover:text-[#ff7500]'
            target='_blank'
            rel='noreferrer'
          >
            <FaFacebook size={20} />
          </a>{' '}
          <a
            href={data.instagram_link}
            className='  text-[#aaaaaa] hover:text-[#ff7500]'
            target='_blank'
            rel='noreferrer'
          >
            <FaInstagram size={20} />
          </a>
          <a
            href={data.linkedIn_link}
            className='  text-[#aaaaaa] hover:text-[#ff7500]'
            target='_blank'
            rel='noreferrer'
          >
            <FaLinkedin size={20} />
          </a>
        </div>
      </div>
    </>
  );
};

export default Footer;
